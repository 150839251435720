import dayjs from 'dayjs';

export const sortByDateProperty = (
  arrayToSort: any[],
  propertyName: string,
): any[] => {
  if (!arrayToSort) return [];
  return arrayToSort.sort(
    (a, b) =>
      dayjs(b[propertyName]).valueOf() - dayjs(a[propertyName]).valueOf(),
  );
};

export default sortByDateProperty;
