export type ValueOf<T> = T[keyof T];

export function swapObject<
  T extends { [K: PropertyKey]: PropertyKey },
  K extends keyof T,
  V extends T[K],
>(obj: T): { [P in V]: K } {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[value as V] = (Number(key) ? Number(key) : key) as K;
    return acc;
  }, {} as { [P in V]: K });
}
