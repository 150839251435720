import Cookies from 'js-cookie';
import MD5 from 'md5';
import axiosPackage, { AxiosError, AxiosRequestConfig } from 'axios';

let tokenIsRefreshing = false;
let requestArray: any[] = [];

const processQueue = (error: AxiosError | null, token = null) => {
  requestArray.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  requestArray = [];
};
const axiosConfig = {
  baseURL: process.env.NEXT_PUBLIC_BE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const axios = axiosPackage.create(axiosConfig);

const fetchRefreshToken = () => {
  const refreshToken = Cookies.get('nploy_refresh_token');

  if (!refreshToken) {
    return Promise.reject(
      new Error('Your refresh token has expired, please log in again.'),
    );
  }

  return axios.post('auth/refresh-token', { refresh_token: refreshToken });
};

export const setTokenDataInCookie = (tokenData: {
  token: string;
  refreshToken: string;
}) => {
  if (typeof window !== 'undefined') {
    Cookies.set('nploy_token', tokenData.token);
    Cookies.set('nploy_refresh_token', tokenData.refreshToken);
  }
};

const axiosInterceptorUnauthorizedHandler = (
  originalRequest: AxiosRequestConfig,
): Promise<any> => {
  const newOriginalRequest = { ...originalRequest };
  return new Promise((resolve, reject) => {
    fetchRefreshToken()
      .then((res) => {
        const { data } = res;
        if (res?.status >= 200 && res?.status < 300) {
          axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
          newOriginalRequest.headers.Authorization = `Bearer ${data.access_token}`;
          processQueue(null, data.access_token);
          const newData = {
            token: data.access_token,
            refreshToken: data.refresh_token,
          };
          setTokenDataInCookie(newData);
          resolve(axios(newOriginalRequest));
        } else {
          // This is an ugly way to suppress the privacy policy calls.
          reject(new Error('401'));
        }
      })
      .catch((err) => {
        Cookies.remove('nploy_token');
        Cookies.remove('nploy_refresh_token');
        processQueue(err, null);
        reject(err);
        throw err;
      })
      .finally(() => {
        tokenIsRefreshing = false;
      });
  });
};

// Add token when reaching to BE on client side
axios.interceptors.request.use((req) => {
  if (typeof window !== 'undefined') {
    const token = Cookies.get('nploy_token');
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      if (tokenIsRefreshing) {
        return new Promise((resolve, reject) => {
          requestArray.push({ resolve, reject, originalRequest });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      originalRequest._retry = true;
      tokenIsRefreshing = true;

      return axiosInterceptorUnauthorizedHandler(originalRequest);
    }

    return Promise.reject(error);
  },
);

const generateDateHash = () => {
  const timeNow = new Date();

  const date = timeNow.getUTCDate();
  const month = timeNow.getUTCMonth() + 1;
  const year = timeNow.getUTCFullYear();

  const hash = MD5(`${date}${month}${year}`);
  return hash;
};

export const axiosForBuild = axiosPackage.create({
  ...axiosConfig,
  headers: {
    'Content-Type': 'application/json',
    'User-Agent': `nploy-${generateDateHash()}`,
  },
});
