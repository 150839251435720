export const initialFiltersValuesData = {
  cities: [],
  country_id: '',
  employment_levels: [],
  employment_types: [],
  experience_years_max: 1,
  experience_years_min: 0,
  jobCategories: [],
  keywords: [],
  salary_max: 0,
  salary_min: 0,
  work_models: [],
};
