import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axios } from '@nploy/shared';
import { IJobCategoryDto } from '@nploy/ui-infrastructure';
import { ICountry, IEmploymentLevel, IEmploymentType } from '@nploy/ui/domain';

export const JOB_CATEGORIES_API = 'JOB_CATEGORIES_API';

export const jobCategoriesApi = createApi({
  reducerPath: JOB_CATEGORIES_API,
  tagTypes: [JOB_CATEGORIES_API],
  baseQuery: async (args) => {
    const { data } = await axios.request(args);
    return {
      data,
    };
  },
  endpoints: (build) => ({
    getJobCategories: build.query<IJobCategoryDto[], string>({
      query: (locale) => ({
        url: 'list/job-occupations',
        method: 'GET',
        params: {
          all: 1,
          locale,
        },
      }),
      providesTags: [JOB_CATEGORIES_API],
    }),
    employmentLevels: build.query<IEmploymentLevel[], void>({
      query: () => ({
        url: 'list/employment-levels',
        method: 'GET',
        params: {
          all: 1,
        },
      }),
      providesTags: [JOB_CATEGORIES_API],
    }),
    employmentTypes: build.query<IEmploymentType[], void>({
      query: () => ({
        url: 'list/employment-types',
        method: 'GET',
        params: {
          all: 1,
        },
      }),
      providesTags: [JOB_CATEGORIES_API],
    }),
    countries: build.query<ICountry[], void>({
      query: () => ({
        url: 'list/countries',
        method: 'GET',
        params: {
          all: 1,
        },
      }),
      providesTags: [JOB_CATEGORIES_API],
    }),
  }),
});

export const {
  useGetJobCategoriesQuery,
  useEmploymentLevelsQuery,
  useEmploymentTypesQuery,
  useCountriesQuery,
} = jobCategoriesApi;
