import Cookies from 'js-cookie';
import { NextRouter } from 'next/router';
import { hardJobsRefreshName } from 'constants/hard-jobs-refresh-name';

export const removeQueryParams = async (
  router: NextRouter,
  paramsToRemove: string[],
) => {
  const { pathname, query } = router;
  if (paramsToRemove.length) {
    paramsToRemove.forEach((param) => delete query[param]);
  }

  return router.replace({ pathname, query }, undefined, {
    shallow: true,
  });
};

export const addHardRefreshCookie = () =>
  Cookies.set(hardJobsRefreshName, 'hard refreshed happens');

export const removeHardRefreshCookie = () => {
  Cookies.remove(hardJobsRefreshName);
};
