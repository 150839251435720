import { swapObject } from '@nploy/shared';

export const jobsCategoriseSearchParams = {
  1: 'accounting-finance-and-statistics',
  3: 'administration-and-secretarial',
  4: 'architecture-and-construction',
  5: 'business-management-and-consulting',
  6: 'catering-hospitality-and-tourism',
  7: 'charity-and-voluntary-work',
  8: 'client-and-customer-service',
  9: 'design-and-creative-arts',
  10: 'education-curriculum-and-teaching',
  11: 'engineers-and-technical-occupations',
  13: 'hr-and-recruitment',
  14: 'insurance',
  15: 'it-and-technology',
  16: 'legal-and-counselling',
  17: 'logistics-and-transportation',
  18: 'marketing-and-communications-and-pr',
  19: 'media-journalism-and-publishing',
  20: 'healthcare-and-therapy',
  21: 'music-entertainment-and-event-management',
  22: 'production-and-manufacturing',
  23: 'property-and-real-estate',
  24: 'sales-and-trade',
  25: 'science-and-research',
  26: 'security',
  27: 'compliance-risk',
} as const;

export const jobsCategoriesShort: Record<
  typeof jobsCategoriseSearchParams[keyof typeof jobsCategoriseSearchParams],
  string
> = {
  'accounting-finance-and-statistics': 'Accounting',
  'administration-and-secretarial': 'Administration',
  'architecture-and-construction': 'Architecture',
  'business-management-and-consulting': 'Business',
  'catering-hospitality-and-tourism': 'Hospitality',
  'charity-and-voluntary-work': 'Charity',
  'client-and-customer-service': 'Customer',
  'design-and-creative-arts': 'Design',
  'education-curriculum-and-teaching': 'Education',
  'engineers-and-technical-occupations': 'Engineers',
  'hr-and-recruitment': 'HR',
  insurance: 'Insurance',
  'it-and-technology': 'IT',
  'legal-and-counselling': 'Legal',
  'logistics-and-transportation': 'Logistics',
  'marketing-and-communications-and-pr': 'Marketing',
  'media-journalism-and-publishing': 'Media, Journalism & Publishing',
  'healthcare-and-therapy': 'Healthcare',
  'music-entertainment-and-event-management': 'Entertainment',
  'production-and-manufacturing': 'Manufacturing',
  'property-and-real-estate': 'Property',
  'sales-and-trade': 'Sales',
  'science-and-research': 'Science',
  security: 'Security',
  'compliance-risk': 'Compliance',
} as const;

export const jobsCategoriseSearchParamsToValues = swapObject(
  jobsCategoriseSearchParams,
);

export const jobsCategoriseShortSearchParamsToValues =
  swapObject(jobsCategoriesShort);
