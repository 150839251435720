export const palette = {
  common: {
    black: '#002435',
    white: '#FFFFFF',
    gray: '#767B7F',
    matterhornSnow: '#E0FCFC',
  },
  primary: {
    main: '#00ECB2',
    light: '#33efc1',
  },
  secondary: {
    main: '#150090',
    light: '#4333a6',
  },

  // Alerts
  success: {
    main: '#009785',
    secondary: '#029785',
  },
  error: {
    main: '#FF6067',
  },
  warning: {
    main: '#FFCC00',
  },
  info: {
    main: '#150090',
  },
  darkError: {
    main: '#db5c61',
  },

  // Grayscale
  black: {
    main: '#002435',
  },
  darkerGrey: {
    main: '#757B7F',
    secondary: '#B6B9BC',
  },
  darkGrey: {
    main: '#B5B9BC',
  },
  grey: {
    main: '#E1E3E4',
  },
  lightGrey: {
    main: '#003933',
  },
  lighterGrey: {
    main: '#009785',
    maltedMintMadness: '#15E7B5',
    lifeless: '#07EBB2',
  },
  paleGrey: {
    main: '#E2F8F5',
    light: '#e7f9f7',
    spanish: '#989898',
    iron: '#5E5E5E',
  },

  text: {
    primary: '#002435',
    secondary: '#767B7F',
  },

  darkBlue: {
    main: '#0E008A',
  },

  // Yellow
  sunglow: {
    main: '#FFCD3D',
  },

  // Page background
  pageBackgroundGrey: {
    main: '#EBF0EF',
  },
  progressBarBackground: {
    main: '#C3CFCD',
  },

  // Social
  google: {
    main: '#FFFFFF',
  },
  facebook: {
    main: '#3C5193',
  },
  twitter: {
    main: '#00AAFF',
  },
  linkedin: {
    main: '#00AAFF',
  },
  youtube: {
    main: '#E10000',
  },
  dribble: {
    main: '#EA4C89',
  },
  behance: {
    main: '#1769FF',
    blueTrust: '#0D0089',
  },
  pinterest: {
    main: '#CD0000',
  },
  daintree: '#082431',
} as const;

export default palette;
