/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import NextScript from 'next/script';
import {
  attributesToProps,
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';

const replaceAToLink = (node: Element) => {
  const { attribs } = node;

  const props = attributesToProps(attribs);

  if (props.href) {
    return (
      <Link {...props} href={props.href as string}>
        {!!node?.children?.length &&
          domToReact(node.children as any, bodyParseOptions)}
      </Link>
    );
  }
};

const replaceImgToImage = (node: Element) => {
  const { attribs } = node;
  const props = attributesToProps(attribs);

  if (Number(props?.width) && Number(props?.height)) {
    return (
      <Image
        alt={props.alt as string}
        src={props.src as string}
        loading="lazy"
        {...props}
      />
    );
  }
  return domToReact(node as any);
};

const replaceDivToDiv = (node: Element) => {
  const { attribs } = node;
  const props = attributesToProps(attribs);

  if (
    props?.className === 'main-header header w-nav' ||
    props?.className === 'main-header header dark w-nav'
  ) {
    return <></>;
  }

  return (
    <div {...props}>
      {!!node?.children?.length &&
        domToReact(node.children as any, bodyParseOptions)}
    </div>
  );
};

const replaceScriptToNextScript = (node: Element) => {
  const { attribs } = node;

  const props = attributesToProps(attribs, 'script');

  return (
    <NextScript {...props} strategy="afterInteractive">
      {!!node?.children?.length && domToReact((node.children[0] as any)?.data)}
    </NextScript>
  );
};

export const replaceSectionToSection = (node: Element) => {
  const { attribs } = node;
  const props = attributesToProps(attribs, 'script');
  return (
    <section {...props}>
      {!!node?.children?.length &&
        domToReact(node.children as any, bodyParseOptions)}
    </section>
  );
};

export const replaceBody = (node: DOMNode) => {
  if (node instanceof Element && node.attribs) {
    if (node.name === 'a') {
      return replaceAToLink(node);
    }
    if (node.name === 'img') {
      return replaceImgToImage(node);
    }
    if (node.name === 'noscript') {
      return <></>;
    }

    if (node.name === 'script') {
      return replaceScriptToNextScript(node);
    }
    if (node.name === 'div') {
      return replaceDivToDiv(node);
    }
    if (node.name === 'section') {
      return replaceSectionToSection(node);
    }
    if (node.name === 'footer') {
      return <></>;
    }
  }
};

export const bodyParseOptions: HTMLReactParserOptions = {
  replace: replaceBody,
  transform: (node, domNode, index) => {
    if (domNode.type === 'script' && domNode.children?.length) {
      return (
        <NextScript key={`next-script-${index}`} strategy="afterInteractive">
          {(domNode.children[0] as any)?.data}
        </NextScript>
      );
    }
    return node as React.JSX.Element;
  },
};
