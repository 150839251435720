import { ICandidateJobDto } from '@nploy/ui-infrastructure';
import { CandidatesRoutes } from 'constants/routes';

const deploymentURL = process.env.NEXT_PUBLIC_DEPLOYMENT_URL;

export const createShareJobUrl = (
  job: ICandidateJobDto,
  lang = '',
  withDeploymentURL = true,
) => {
  const { deepLink, urlParams, isExternal } = job;

  if (deepLink) return deepLink;

  if (!urlParams) return '';

  const { countryName, jobCategory, city, jobRole, jobId } = urlParams;

  if (!(city && countryName && jobCategory && jobId && jobRole)) return '';

  const baseURL = withDeploymentURL ? `${deploymentURL}/${lang}` : lang;

  return `${baseURL}/${
    CandidatesRoutes.jobs
  }/${countryName}/${jobCategory}/${city}/${jobRole}/${jobId}${
    isExternal ? '-X' : ''
  }`;
};

export const createJobPath = (job: ICandidateJobDto) => {
  if (!job) return '';

  const { urlParams } = job;

  if (urlParams) {
    const { countryName, jobCategory, city, jobRole, jobId } = urlParams;

    if (city && countryName && jobCategory && jobId && jobRole) {
      return `/${CandidatesRoutes.jobs}/${countryName}/${jobCategory}/${city}/${jobRole}/${jobId}`;
    }
  }

  return '';
};
